.home-container {
  /* background-color: var(--primary-background-light); */
  /* width: 100%; */
  height: fit-content;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
}
.component-container {
  width: 100% !important;
  /* height: 120vh; */
  overflow-x: none !important;
  display: flex;
  flex-direction: column;
}
/* @media screen and (max-width: 880px) {
  .component-container {
    overflow-x: none !important;
    width: 95%;
  }
} */
.component-container-body {
  width: 100% !important;
}
.component-container-header {
  /* margin: 14px; */
  /* margin: var(--default-padding); */
  /* height: 70px; */
  /* background-color: red; */
  background-color: var(--secondary-background);
  color: #f5f5f5;
  font-size: 28px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component-container-header p {
  padding: 14px;
  margin: 0;
  line-height: 40px;
}
.middle-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100vh; /* Makes the container full height of the viewport */
}

.welcome-message {
  font-size: 3em; /* Adjust font size as needed */
}
.action-menu-container {
  position: absolute;
  z-index: 1000;
}
