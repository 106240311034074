.developer-main-container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  gap: 48px;
  background-color: white;
}
/*-----------Developer Header-Section----------------*/
.developer-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  /* border: 1px solid #cacfd8; */
}
.breadcrumb-section {
  display: flex;
  align-items: center;
}
.breadcrumb-text {
  color: var(--state-feature-dark, #351a75) !important;
  text-align: center;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.developer-row-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.developer-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 40px;
  letter-spacing: -0.12px;
  align-self: stretch;
}
.description {
  color: var(--Gray-600, #475467);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  align-self: stretch;
}
.cancel-btn-text {
  color: var(--text-sub-600, #525866) !important;
  text-transform: capitalize !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
  background: var(--bg-white-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);

  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.save-btn-text {
  border-radius: var(--radius-10, 10px);
  background: #512dd6 !important;
  margin-left: 15px !important;
  text-transform: capitalize !important;
}

/*---------------Api Credential------------------*/

.api-credentials-container {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #cacfd8;
}
.credential-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  letter-spacing: -0.27px;
  margin-bottom: 20px !important;
}
/*----------------Web Hooks------------------------*/
.webhooks-main-container {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #cacfd8;
  min-width: 220px !important;
}
.webhooks-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.27px;
  display: flex;
  align-self: stretch;
}

/*-------------Checkout Settings------------------*/
.checkout-setting-container {
  display: flex;
  padding: 16px 24px;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  border-radius: 8px;
  border: 1px solid #cacfd8;
}
.checkout-main-container {
  width: 40%;
}
.checkout-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.27px;
  display: flex;
  align-self: stretch;
}

/* @media screen and (max-width: 880px) {
  .developer-text {
    font-size: 12px !important;
  }
  .api-tabs {
    width: 10px !important;
    font-size: 10px !important;
    margin: 0px !important;
    padding: 0px !important;
    letter-spacing: 0px !important;
  }
  .webhook-tabs {
    width: 90px !important;
    font-size: 10px !important;
    margin: 0px !important;
    padding: 0px !important;
    letter-spacing: 0px !important;
    margin-left: -5px !important;
  }
  .google-tabs {
    width: 90px !important;
    font-size: 10px !important;
    margin: 0px !important;
    padding: 0px !important;
    letter-spacing: 0px !important;
    margin-left: 5px !important;
  }
  .css-19kzrtu {
    margin-top: 2px !important;
    padding-left: 0px !important;
  }
  .developer-main-container {
    padding: 15px 35px 10px 10px !important;
  }
  .credential-text {
    font-size: 12px !important;
  }
} */

.MuiTabs-flexContainer {
  display: flex;
  flex-wrap: wrap !important;
}
.MuiTabs-indicator {
  background-color: transparent !important;
}
