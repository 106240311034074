.select-dropdown {
  /* width: 170px !important; */
  width: 100%;
}
/* @media screen and (max-width:1250px) {
  .select-dropdown {
    width: 120px !important;
  }
} */

/* @media screen and (max-width: 991px) {
  .multi-select-dropdown {
    width: 125px;
  }
} */

/* .multi-select-dropdown {
  width: 100%;
  background-color: plum !important;
}

@media screen and (min-width: 1270px) {
  .multi-select-dropdown {
    width: 180px;
  }
} */
.bg-color {
  background-color: #fff !important;
}