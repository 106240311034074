.container {
  background-color: white;
  min-height: 100vh;
}

.page-container {
  padding: 32px 32px 32px 0px;
  background-color: #f9f9f9;
}

.breadcrumb-container {
  /* padding-bottom: 24px; */
  padding: 18px 30px;
  background-color: white;
  border-bottom: solid 1px #e5e5e5;
  border-top: solid 1px #e5e5e5;
  margin-bottom: 20px;
}

.header-container {
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  display: flex;
  gap: 16px;
  padding-bottom: 48px;
}
.header-title-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  align-items: baseline;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .header-title-container {
    max-width: 100%;
  }
}

.header-action {
  cursor: pointer;
  justify-content: space-between;
  border-radius: var(--radius-10, 10px);
  background-color: #1b84ff;
  align-self: start;
  display: flex;
  gap: 4px;
  font-size: 14px;
  color: var(--static-static-white, #fff);
  font-weight: 500;
  letter-spacing: -0.08px;
  line-height: 143%;
  padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
}

.creds-label {
  width: Fixed (416px) px;
  height: Hug (40px) px;
  gap: 14px;
  text-align: left;
  .text {
    font-family: Inter !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: var(--text-strong-950, #0e121b);
  }
  .sub-text {
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-sub-600, #525866);
  }
}
.save-button {
  height: 40px;
  background-color: #512dd6 !important;
  border-radius: 10px !important;
}
.integrate-button {
  height: 40px;
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
  color: var(--text-sub-600, #525866) !important;
  border-radius: 10px !important;
}
.header-action-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 10px !important;
  height: 10px !important;
}
.header-action-title {
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter, sans-serif;
}

.menu-header {
  background: var(--bg-weak-50, #f5f7fa);
  width: 100%;
  height: 32px;
  padding: 8px 20px 8px 20px;
  gap: 6px;
  opacity: 0px;
  font-family: Inter !important ;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #1b84ff !important;
}

.currency-name {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  color: var(--text-strong-950, #0e121b);
  margin-left: 8px !important;
  margin-right: 10px !important;
  width: 150px;
}

.currency-code {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--text-soft-400, #717784);
  width: "20px";
  margin-left: 8px !important;
  margin-right: 10px !important;
}

.filter-container {
  align-self: stretch;
  display: flex;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.08px;
  line-height: 143%;
  padding-bottom: 16px;
}
@media (max-width: 991px) {
  .filter-container {
    flex-wrap: wrap;
  }
}
.filter-toggle-container {
  align-items: center;
  border-radius: var(--radius-10, 10px);
  background-color: var(--bg-weak-50, #f5f7fa);
  display: flex;
  gap: 4px;
  color: var(--text-soft-400, #717784);
  white-space: nowrap;
  text-align: center;
  padding: 2px;
  height: 40px;
}
@media (max-width: 991px) {
  .filter-toggle-container {
    white-space: initial;
  }
}
.filter-toggle-item.selected {
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: var(--radius-6, 6px);
  box-shadow: 0px 6px 10px 0px rgba(14, 18, 27, 0.06),
    0px 2px 4px 0px rgba(14, 18, 27, 0.03);
  background-color: var(--bg-white-0, #fff);
  align-self: stretch;
  color: var(--text-strong-950, #0e121b);
  flex: 1;
  padding: 4px;
}
@media (max-width: 991px) {
  .filter-toggle-item {
    white-space: initial;
  }
}
.filter-toggle-item {
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter, sans-serif;
  align-self: stretch;
  flex: 1;
  margin: auto 0;
}

.filter-action-container {
  justify-content: end;
  display: flex;
  padding-left: 80px;
  gap: 12px;
  color: var(--text-sub-600, #525866);
  flex: 1;
}
@media (max-width: 991px) {
  .filter-action-container {
    flex-wrap: wrap;
  }
}
.filter-action-item {
  justify-content: space-between;
  border-radius: var(--radius-8, 8px);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  border-color: rgba(202, 207, 216, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--bg-white-0, #fff);
  display: flex;
  gap: 4px;
  white-space: nowrap;
  padding: 8px;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 991px) {
  .filter-action-item {
    white-space: initial;
  }
}
.filter-action-item-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
}

.filter-action-title {
  color: var(--text-sub-600, #525866);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  font-family: Inter, sans-serif;
}

.header-action.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.settings-img-container {
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 999px);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  border-color: rgba(202, 207, 216, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--bg-white-0, #fff);
  display: flex;
  padding: 4px;
  margin-right: 5px;
  margin-left:10px;
}
.settings-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  padding: 4px;
  width: 24px;
  height: 24px;
}

.settings-title-container {
  display: flex;
  align-items: center;
}

/* .settings-title {
  padding-left: 5px;
} */

.popup-description {
  color: var(--text-strong-950, #0e121b);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
@media screen and (max-width: 576px) {
  .popup-description {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }
}

.cancel-confirmation-button {
  cursor: pointer;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter, sans-serif;
  border-radius: var(--radius-8, 8px);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  border-color: rgba(202, 207, 216, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--bg-white-0, #fff);
  color: var(--text-sub-600, #525866);
  padding: 8px;
  height: 36px;
  font-size: 14px;
  font-style: normal;
  width: 150px !important;
}
.ok-confirmation-button {
  cursor: pointer;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter, sans-serif;
  border-radius: var(--radius-8, 8px);
  background-color: #512dd6;
  color: var(--bg-white-0, #fff);
  border: none;
  padding: 8px 19px;
  height: 36px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 150px !important;
}

/* :root {
  --main-title-font-size: 15px;
  --main-sub-title-font-size: 12px;
  --button-font-size: 10px;
  --button-img-font-size: 10px;
  --table-header-font-size: 10px;
  --table-body-font-size: 10px;
  --table-chip-font-size: 10px;
  --table-status-font-size: 10px;
  --filter-tabs-font-size: 12px;
  --action-dots-items-font-size: 10px;
} */
.home-table-container {
  width: 100%;
}

/* @media screen and (max-width: 880px) {
  .header-title {
    font-size: var(--main-title-font-size) !important;
    padding: 0px !important;
  }
  .header-sub-title {
    font-size: var(--main-sub-title-font-size) !important;
  }
  .header-action {
    font-size: var(--button-font-size) !important;
  }
  .header-action-img {
    font-size: var(--button-img-font-size) !important;
  }
  .home-table-container {
    width: 100%;
  }
  .header-container {
    width: 100%;
    padding-bottom: 14px !important;
  }
  .filter-container {
    width: 100%;
  }
  .action-menu-item {
    font-size: var(--action-dots-items-font-size) !important;
  }
  .page-container {
    padding: 5px 35px 10px 10px !important;
  }
  .popup-description {
    font-size: 12px !important;
  }
  .ok-confirmation-button,
  .cancel-confirmation-button {
    padding: 5px !important;
    font-size: 12px !important;
    width: 100px !important;
  }
}
@media screen and (max-width: 576px) {
  .home-table-container {
    width: 96%;
  }
  .header-container {
    width: 96%;
  }
  .filter-container {
    width: 96%;
  }
  .header-action {
    margin-right: 5px !important;
  }
  .spinner {
    height: 30px !important;
    width: 30px !important;
    border: 5px solid !important;
    border-color: #dbdcef !important;
    border-right-color: #512dd6 !important;
    animation: spinner-d3wgkg 1s infinite linear;
  }
} */

/* 3Dots */

.dropdown-menu-list-items {
  position: relative;
  display: inline-block;
}

.action-menu-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 120px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 5px;
  right:0;
  border-radius: 8px;
  transform: 0.5s ease-in-out;
  transition: transform 0.5s;
}

table tr:nth-last-child(1) .dropdown-menu-list-items .menu,
table tr:nth-last-child(2) .dropdown-menu-list-items .menu {
  bottom: 100%;
  top: auto;
  margin-bottom: 5px;
}

.menu-item {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: -1;
}

.menu-item:hover {
  background-color: #e9f3ff;
  border: 3px solid #1b84ff;
  border-radius: 8px;
  padding: 3px;
}

.action-menu-icon {
  margin-right: 10px;
}

.test {
  display: none;
}

.MuiTabs-fixed {
  width: 280px !important;
}

