.breadcrumb-item {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  font-size: 14px;
  color: var(--primary-darker, #0b0b2d);
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.breadcrumb-img-text-container {
  display: flex;
  text-wrap: wrap;
}

.containers {
  display: flex;
  justify-content: space-between; /* Space out the content */
  align-items: center; /* Vertically center the items */
  width: 100%;
  flex-wrap: wrap;
}
.breadcrumb-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  cursor: pointer;
}

.breadcrumb-title {
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter, sans-serif;
  cursor: pointer;
  /* text-wrap: wrap; */
  /* word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; */
}

.language-select-wrapper label {
  margin-right: 20px;
  white-space: nowrap;
  font-size: 13px;
  font-family: Inter !important;
}
.language-select-wrapper select {
  padding: 0 15px 0 10px !important;
  height: 30px !important;
  font-size: 13px !important;
}
