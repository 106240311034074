/* .processor-setting-container {
  padding: var(--deafult-padding) 0;

  width: 100%;
  border-bottom: 1px dashed #707070;
}
.processor-setting-body {
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0 24px;
  font-size: 20px;
  flex-direction: column;
  font-weight: 700;
}
.processor-setting {
  padding: 0 24px;
  margin-top: 16px;
}
.processor-table {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 0;
  width: 100%;
}
.processor-details {
  padding: 16px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.processor-details p,
.processor-table p {
  margin-bottom: 16px;
}
.processor-settings-buttons {
  display: flex;
  margin-left: auto;
  margin-top: var(--deafult-padding);
}
.processor-settings-buttons button {
  padding: 10px var(--deafult-padding);
  margin-right: var(--deafult-padding);
  width: 100px;

  border-radius: 4px;

  font-weight: 600;
  cursor: pointer;
}
.processor-settings-buttons :nth-child(1) {
}
.processor-settings-buttons :nth-child(2) {
}
.processor-details-input {
  padding: 10px;
}
.paymnent-currency-list {
  margin-left: 10px;
}
.credential-data-container {
  display: flex;
  margin: 10px 20px;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .credential-data-container {
    display: flex;
    flex-wrap: wrap;
  }
} */

.processor-setting-container {
  padding: var(--deafult-padding) 0;
  /* margin-top: 20px; */
  /* height: 300px; */
  /* background-color: aqua; */
  width: 100%;
  border-bottom: 1px dashed #707070;
}
.processor-setting-body {
  /* background-color: red; */
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0 24px;
  font-size: 20px;
  flex-direction: column;
  font-weight: 700;
}
.processor-setting {
  padding: 0 24px;
  margin-top: 16px;
}

.processor-table {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 0;
  width: 100%;
  /* align-self: center; */
}
.processor-details {
  padding: 16px 0;
  /* margin: 16px 0; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.processor-details p,
.processor-table p {
  margin-bottom: 16px;
}
.processor-settings-buttons {
  display: flex;
  margin-left: auto;
  margin-top: var(--deafult-padding);
}
.processor-settings-buttons button {
  padding: 10px var(--deafult-padding);
  margin-right: var(--deafult-padding);
  width: 100px;
  /* border: none; */
  border-radius: 4px;
  /* color: #f5f5f5; */
  font-weight: 600;
  cursor: pointer;
}
.processor-settings-buttons :nth-child(1) {
  /* background-color: crimson; */
}
.processor-settings-buttons :nth-child(2) {
  /* background-color: dodgerblue; */
}
.processor-details-input {
  padding: 10px;
  /* border-radius: 4px; */
}
.paymnent-currency-list {
  margin-left: 10px;
}
.credential-title {
  margin-bottom: 10px !important;
  text-align: start !important;
}

@media screen and (min-width: 576px) {
  .connection-name-container {
    display: flex;
  }
}

@media screen and (min-width: 576px) {
  .credential-and-properties-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .credential-sub-title-text {
    width: 50%;
    display: flex;
    align-items: center;
  }
}

.dropdown-menu-popup {
  position: relative;
  display: inline-block;
}

.menu-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 5px;
  margin-left: -25px;
  border-radius: 8px;
}

table tr:nth-last-child(1) .dropdown-menu-popup .menu-dropdown {
  top: auto;
  bottom: 100%;
  margin-bottom: 5px;
}

.set-as-menu-item {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
}

.set-as-menu-item:hover {
  background-color: #e9f3ff;
  border: 3px solid #1b84ff;
  border-radius: 8px;
  padding: 2px;
}

.action-menu-icon {
  margin-right: 10px;
}
.add-currency-list-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin-bottom: 10px;
}

.add-currency-list-items:hover {
  /* background-color: rgb(188, 209, 245); */
  /* background-color: rgb(178, 206, 233) !important; */
  background-color: #e9f3ff;
  border: 3px solid #1b84ff;
  border-radius: 8px;
}
.dot-circle {
  color: #1b84ff;
}
.add-currency-text {
  background: #e9f3ff;
  padding: 8px 20px 8px 20px;
  gap: 6px;
  opacity: 0px;
  font-family: Inter;
  font-size: 12px !important;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--text-soft-400, #99a0ae);
}
.add-currency-name {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: black;
  margin-left: 10px !important;
}

table tr:nth-last-child(1) .add-currency-dropdown .dropdown-currency-list {
  top: auto;
  bottom: 100%;
  /* margin-bottom: 5px; */
  overflow: scroll !important;
}

table tr:nth-last-child(2) .add-currency-dropdown .dropdown-currency-list {
  /* top: auto;
  bottom: 100%;
  margin-bottom: 5px; */
}

.table-responsive-container {
  overflow-x: scroll !important;
}

.dropdown-menu-popup {
  overflow: auto !important;
}

/* .table-scroll-wrapper {
  width: 100%;
  overflow-x: scroll !important;
} */
/* .placement-top {
  max-height: 600px;
  overflow-y: auto;
  background-color: white;
} */
/* .ul.base-Menu-listbox {
  max-height: 500px !important;
  overflow-y: auto;
} */
