.logo-url-add-url-row-container {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  margin: 20px 0px;
}
.logo-url-container {
  display: flex;
  width: 500px;
  align-items: center;
  gap: 14px;
}
.logo-url {
  color: var(--text-strong-950, #0e121b) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.add-logo-label-container {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8);
  background: var(--bg-white-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  width: 100%;
}
.logo-url-search {
  color: var(--text-soft-400, #717784);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  flex: 1 0 0;
}
