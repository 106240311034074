.left-right-space {
  margin: 0px 25px !important;
}

@media screen and (max-width: 576px) {
  .left-right-space {
    margin: 0px !important;
  }
}
.payment-processor-search{
  height: 7%;
  width: 90%;
  margin: 0 auto;
}
@media screen and (min-width: 640px) {
  .space-between {
    display: flex;
    justify-content: space-between !important;
  }
}

.add-payment-container {
  height: 90% !important;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
