a {
  color: black;
}
.payment-processor-item {
  cursor: pointer;
  border-radius: var(--radius-12, 12px);
  /* border-color: rgba(94, 94, 127, 1); */
  border-style: solid;
  border-width: 1px;
  background-color: var(--bg-white-0, #fff);
  display: flex;
  width: 250px !important;
  height: 150px;
  flex-direction: column;
  padding: 14px 16px;
  margin: 10px;
}

.payment-processor-item-selection-container {
  display: flex;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
}
.payment-processor-item-title-container {
  display: flex;
  margin-top: 4px;
  gap: 15px;
}
.payment-processor-item-img-container {
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border-color: rgba(202, 207, 216, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  display: flex;
  padding: 10px 5px;
}
.payment-processor-item-img {
  aspect-ratio: 1.23;
  object-fit: contain;
  object-position: center;
  width: 67px;
}
.payment-processor-item-title {
  color: #000;
  margin: auto 0;
  font: 500 14px Inter, -apple-system, Roboto, Helvetica, sans-serif;
  width: 70px;
}
.payment-processor-item-radio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
}
.payment-processor-item-subtitle {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: var(--text-sub-600, #525866);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  margin-top: 16px;
  font: 400 12px/16px Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

.payment-processor-item-not-selected {
  border-color: #cacfd8;
}
.payment-processor-item-selected {
  border-color: #1b84ff;
}

.dropdown-description-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
