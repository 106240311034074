:root {
  --deafult-padding: 16px;
  --primary-background-dark: #21251f;
  --primary-background-light: #f5f5f5;
  --secondary-background: #4e515c;
  --theme-color: #7862f8;
  --accent-color: #c8f560;
  --accent-color-highlight: #a0c44c;
}
body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 13px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* ::-webkit-scrollbar {
  display: none;
} */
code {
  font-family: "Inter", serif;
}
p {
  margin: 0;
  padding: 0;
}
