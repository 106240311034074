/* .header-container {
  display: flex;
  justify-content: space-between;
}

.header-buttons-container {
  padding-right: 15px;
}

.processor-settings-container {
  padding: var(--deafult-padding) 24px;
}
.error-name-show {
  margin-bottom: -20px;
  padding-top: 3px;
  text-align: start;
  padding-left: 10px;
  padding-bottom: 5px;
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

.error-name-hide {
  display: none;
}
.error-name-input-border {
  border: 1px solid rgb(221, 33, 33) !important;
  border-radius: 5px;
}
.name-input-border {
  border-radius: 5px;
  border: 1px solid gray !important;
}
.new-processor-header-sub-title {
  color: var(--Gray-600, #475467);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  letter-spacing: -0.08px;
  margin-top: 12px;
  font: 400 14px/143% Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.header-buttons-container {
  display: flex;
  gap: 10px !important;
  flex-wrap: wrap;
}

@media screen and (max-width: 880px) {
  .new-processor-header-sub-title {
    font-size: 12px !important;
  }

  .integrate-button {
    font-size: 10px !important;

    margin-bottom: 10px !important;
    height: 35px !important;
  }
  .save-button {
    font-size: 10px !important;
    height: 35px !important;
  }
  .connection-name {
    font-size: 10px !important;
  }
  .input-field .MuiOutlinedInput-root input {
    height: 28px !important;
    font-size: 10px !important;
  }
  .input-field .MuiOutlinedInput-root {
    height: 28px !important;
  }
  .error-name-show {
    font-size: 10px !important;
  }
  .MuiFormHelperText-root {
    font-size: 10px !important;
  }
  .currency-name {
    font-size: 10px !important;
  }
  .currency-code {
    font-size: 10px !important;
  }
  .dot-circle {
    font-size: 10px !important;
  }
  .header-buttons-container {
    gap: 5px !important;
  }
  .creds-title {
    font-size: 15px !important;
    margin-bottom: 10px !important;
  }
  .chip-text .MuiChip-label {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 576px) {
  .integrate-button {
    font-size: 10px !important;
    margin-bottom: 10px !important;
    height: 27px !important;
    border-radius: 5px !important;
  }
  .save-button {
    font-size: 10px !important;
    height: 27px !important;
    padding: 10px !important;
    border-radius: 8px !important;
  }
} */

.header-container {
  display: flex;
  justify-content: space-between;
}

.processor-settings-container {
  padding: var(--deafult-padding) 24px;
}
.error-name-show {
  margin-bottom: -20px;
  padding-top: 3px;
  text-align: start;
  padding-left: 10px;
  padding-bottom: 5px;
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

.error-name-hide {
  display: none;
}
.error-name-input-border {
  border: 1px solid rgb(221, 33, 33) !important;
  border-radius: 5px;
}
.name-input-border {
  border-radius: 5px;
  border: 1px solid gray !important;
}
.new-processor-header-sub-title {
  text-align: start;
  color: var(--Gray-600, #475467);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  letter-spacing: -0.08px;
  margin-top: 12px;
  font: 400 14px/143% Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.header-buttons-container {
  display: flex;
  gap: 10px !important;
  flex-wrap: wrap;
}
