.transaction-main-container {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.transactions-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
}

.transaction-header-container {
  height: 50px;
  display: flex !important;
  align-items: center !important;
  gap: 16px !important;
  align-self: stretch;
  margin-bottom: 40px;
}
@media screen and (max-width: 450px) {
  .pl {
    padding-left: 20px !important;
  }
}

.transaction-reference-id-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.transaction-left-icon-container {
  display: flex;
  align-items: center;
}

.ref-id-text {
  color: var(--Gray-900, #101828) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 40px;
  letter-spacing: -0.12px;
  margin-right: 10px !important;
  /* white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word; */
}

.ref-id {
  color: var(--Gray-900, #101828) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 40px;
  /* width: 280px; */
}

.failed-btn {
  display: flex;
  padding: 4px 8px 4px 4px;
  text-transform: capitalize !important;
  border-radius: var(--radius-10, 10px) !important;
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
  background: var(--bg-white-0, #fff);
  color: var(--text-sub-600, #525866) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
}
.frame {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #cacfd8;
  margin-bottom: 20px;
}
.frame-hide {
  display: none;
}
.sub-title {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: -0.27px;
  display: flex;
}
.setttings-row-container {
  display: flex;
  flex-wrap: wrap;
}

.each-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 2px;
  width: 416px;
}

.hide-container {
  display: none;
}
@media screen and (max-width: 991px) {
  .each-container {
    width: 240px;
  }
}

.label-text {
  color: var(--text-strong-950, #0e121b) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
}
.get-user-text {
  color: var(--text-sub-600, #525866);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.status-button {
  margin-left: 15px !important;
}

@media screen and (max-width: 375px) {
  .each-container {
    width: 200px !important;
  }
  .sub-title {
    font-size: 12px !important;
  }
  .label-text {
    font-size: 12px !important;
  }
  .get-user-text {
    font-size: 12px !important;
  }
}
