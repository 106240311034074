.rules-parent-container {
  border-radius: var(--radius-12, 12px) var(--radius-12, 12px) 0px 0px;
  border: 1px solid #99a0ae;
  background: #fff;
}
.rules-condition-parent-container {
  /* margin-top: 10px; */
  display: flex;
  /* padding: 16px !important; */
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  /* border-radius: var(--radius-12, 12px) var(--radius-12, 12px) 0px 0px;
  border: 1px solid #99a0ae; */
  background: #fff;
}
.rules-condition-container {
  /* border: 1px solid red; */
  /* padding: 5px; */
  margin: 0;
  text-align: left;
}
.conditions-main-container {
  padding: 15px;
}
.rules-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
  /* width: 100% !important; */
  padding: 12px 20px 12px 12px;
  gap: 12px;
  border-radius: 8px;
  background: #e9f3ff;
}

.rules-header > button {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2, 2px);
  border-radius: 6px;
  border: 1px solid #9c9cad;
  background: var(--bg-white-0, #fff);
}
/* .amount-text-field {
  width: 100%;
} */
/* @media screen and (max-width:1250px) {
  .amount-text-field {
    width: 120px;
  }
} */

.rules-condition-container {
  gap: 10;

  /* padding: 10px 20px;
  border: 1px solid black;
  margin::"10px";
  border-radius: 5px;
  background-color: var(--secondary-background);
  color: #fff; */
}
.rules-condition-container > div > button {
  border-radius: 6px;
  border: 1px solid #9c9cad;
  background: var(--bg-white-0, #fff);
}

.rules-condition-container > div > button:hover {
  background-color: #fff;
}

.rules-condition-container > div {
  gap: 20px;
}
/* .add-nested-condition-hr-line {
  display: flex;
  align-items: center;
  margin-left: -55px;
  gap: 4px;
  position: relative;
}
.add-nested-condition-hr-line::before {
  content: "";
  list-style: none;
  width: 40px;
  border-bottom: 1px dashed grey;
}
.add-nested-condition-hr-line > div {
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
  font-size: 16px;
  align-items: center;
} */

.rules-condition-nested-container {
  list-style: none;
  border-left: 1px dashed grey;
  margin-top: 5px;
  padding: 10px 20px 0px 20px;
  margin-left: 85px;
  /* background:green; */
}

.processor-operation {
  position: relative;
  padding-bottom: 10px;
  width: 300px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  left: -30px;
}
.processor-operation::before {
  content: "";
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
}
.processor-element {
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
}
.processor-operation:last-child {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, white 50%);
}

/* .processor-operation + .processor-operation {
  background-color: transparent;
} */

.rule-conditions-nested-container-parent {
  display: flex;
  align-items: center;
  padding-left: 5px;
  gap: 4px;
  position: relative;
  width: 100%;
}
.nested-operation {
  position: relative;
  /* padding-bottom: 10px; */
  margin-top: 10px;
  display: flex;
  align-items: center;
  left: -30px;
}
.nested-operation::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
}
.nested-element {
  display: flex;
  align-items: center;
  background-color: white;
}
.nested-operation:last-child {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, white 50%);
}

.nested-operation + .nested-operation {
  background-color: transparent;
}
.rule-conditions-nested-container-parent::before {
  content: "";
  list-style: none;
  width: 20px;
  border-bottom: 1px dashed grey;
}
.rule-conditions-nested-container-parent > div {
  display: flex;
  flex-direction: row;
  padding: 5px;
  gap: 10px;
  font-size: 16px;
  align-items: center;
}

.rules-condition-container :nth-child(1) {
  /* margin-left: 40px; */
}
.rules-condition-container :nth-child(2) {
  /* margin-left: 40px; */
}

.rules-condition-container :nth-child(3) {
  /* margin-left: 40px; */
}
/* input.apple-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 44px;
  height: 26px;
  background-color: #fff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
}

input.apple-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background: transparent;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.apple-switch:checked {
  box-shadow: inset 20px 0 0 0 #512dd6;
  border-color: #512dd6;
}

input.apple-switch:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
} */

.error-text {
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.text {
  display: none;
  /* width: 400px !important; */
  margin-left: 160px !important;
}
.select-error-border {
  border: 3px solid red !important;
  /* background-color: plum; */
  padding: 30px;
}
.operation-error-message {
  /* margin-left: 190px !important; */
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.value-error-message {
  /* margin-left: 380px !important; */
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}

/* @media screen and (max-width: 991px) {
  .rules-setting-body {
    padding: 5px !important;
    flex-wrap: wrap !important;
    overflow-x: scroll !important;
    width: 90% !important;
  }
  .rules-header {
    margin-left: -32px !important;
    padding: 5px !important;
    gap: 5px !important;
  }

  .and-text,
  .or-text {
    font-size: 10px !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  .add-condition-button,
  .add-nested-condition {
    font-size: 10px !important;
  }
  input.apple-switch {
    height: 15px !important;
    width: 32px !important;
    margin-bottom: -2px !important;
  }
  input.apple-switch:after {
    height: 0px !important;
  }
  .rules-condition-nested-container {
    margin-left: 17px !important;
  }
  .MuiSelect-outlined {
    font-size: 12px !important;
    padding: 5px !important;
  }
  .amount-text-field .MuiOutlinedInput-input {
    font-size: 12px !important;
  }
  .operation-error-message .value-error-message {
    margin-left: -5px !important;
  }
} */

/* @media screen and (max-width: 1070px) {
  .rules-setting-body {
    width: 88% !important;
    overflow-x: scroll !important;
  }
} */

.rules-dropdown-container {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  position: relative;
}
.rules-dropdown-error-message {
  position: absolute;
  bottom: -33px;
  left: 0;
  font-size: 12px;
  color: rgb(221, 33, 33);
  text-wrap: wrap;
}
