.payment-transaction-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap !important;
  padding: 32px !important;
  flex-direction: column;
  gap: 48px;
  /* width: 100%; */
  /* background-color: green; */
}
.select-payment-transaction-container {
  /* width: 90%; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap !important;
  padding: 32px !important;
  flex-direction: column;
  gap: 48px;
  /* background-color: rgb(158, 185, 158); */
}

.AddIcon {
  color: "white";
}
/* .row-width{
  width:100%;
background: #1ad3d3;
} */
.transaction-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  /* border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8); */
}
.transaction-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  /* line-height: 40px; */
  letter-spacing: -0.12px;
}
.description-text {
  color: var(--Gray-600, #475467);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: -0.084px;
  margin-top: -15px;
}

.calendar-container {
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: flex-start !important;
  cursor: pointer;
  margin-bottom: 10px;
}

.search-container {
  width: 230px;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 8px;
  /* align-self: stretch; */
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8);
  background: var(--bg-white-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  height: 43px;
  /* cursor: pointer; */
}
.search-container input,
.search-container fieldset {
  border: none !important;
  height: 40px !important;
  padding: 0 !important;
}
.filter-button {
  display: flex;
  padding: 3px 15px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 0.375rem;
  background: #e9f3ff;
  /* margin-left: 10px; */
  cursor: pointer;
  color: #1b84ff;
  margin-right: 10px;
}
.filter-button p {
  color: #1b84ff;
}
.filter-button svg path {
  fill: #1b84ff;
}
.filter-button:hover {
  background: #1b84ff;
}
.filter-button:hover p {
  color: #fff;
}
.filter-button:hover i.ki-duotone.ki-filter {
  color: #fff;
}
i.ki-duotone.ki-filter {
  color: #1b84ff;
}
.caret-down-icon {
  color: white !important;
}
.reload-button {
  display: flex;
  padding: 10px 12px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--radius-10, 10px);
  color: var(--text-sub-600, #525866) !important;
  margin-left: 5px;
  font-size: 14px !important;
  text-transform: capitalize !important;
  border-radius: var(--radius-10, 10px) !important;
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
  margin-left: 5px !important;
}

.filter-container-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  border: 1px solid var(--stroke-soft-200, #e1e4ea);
  border-radius: 8px;
  width: 100%;
}
/* .expanded-filter-container-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 16px 24px;
  gap: 16px;
  border: 1px solid var(--stroke-soft-200, #e1e4ea);
  border-radius: 8px;
  width: 95% !important;
}

@media screen and (max-width: 1450px) {
  .filter-container-details {
    display: flex;
    flex-wrap: wrap !important;
    overflow-x: scroll !important;
    width: 90% !important;
  }
  .expanded-filter-container-details {
    display: flex;
    flex-wrap: wrap !important;
    width: 76% !important;
    overflow-x: scroll !important;
  }
}
@media screen and (max-width: 1070px) {
  .filter-container-details {
    display: flex;
    flex-wrap: wrap !important;
    overflow-x: scroll !important;
    width: 88% !important;
  }
  .expanded-filter-container-details {
    display: flex;
    flex-wrap: wrap !important;
    width: 81% !important;
    overflow-x: scroll !important;
  }
} */
.filters-text {
  display: flex;
  color: var(--Gray-900, #101828) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.27px;
}
.filter-frame-container {
  display: flex;
  /* justify-content: space-between !important; */
  flex-wrap: wrap !important;
  gap: 19px;
}
/* .label-bold-container {
  display: "flex";
  flex-direction: column;
  width: 25%;
  min-width: 200px;
} */
/* .each-filter-frame {
  display: flex;
  width: 390px;
  flex-direction: column;
  align-items: flex-start !important;
  cursor: pointer;
} */

.each-filter-frame {
  width: 30%;
  min-width: 300px;
  text-align: start;
  cursor: pointer;
}
.gap {
  gap: 10px;
}
@media screen and (max-width: 991px) {
  .each-filter-frame {
    width: 45% !important;
    min-width: 300px !important;
  }
}
@media screen and (max-width: 576px) {
  .each-filter-frame {
    width: 100% !important;
    min-width: 180px !important;
  }
}

.hide-date-frame {
  display: none;
}

.filter-label-text {
  color: #0e121b;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: -0.084px;
  margin-bottom: 4px !important;
}
.filter-label-text-bold {
  font-weight: bold !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.084px;
  margin-bottom: 4px !important;
}
.drop-down-container {
  display: flex;
  padding: 10px 10px 10px 12px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
}

.click-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.placehoder-text {
  color: var(--text-soft-400, #717784);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.cart-down-icon {
  width: 20px;
  height: 20px;
}

.pop-up-alignment {
  margin-top: 5px;
  margin-left: -12px;
}
.drop-down-menu-item {
  display: flex;
  flex-direction: column !important;
  /* width: 385px; */
  width: 30% !important;
  min-width: 300px;
  background-color: white !important;
}
.payment-type-popup-height {
  flex-direction: column !important;
  height: 500px;
  overflow-y: scroll;
  /* width: 385px; */
  width: 30% !important;
  min-width: 300px;
  background-color: white !important;
}

.button-alignment {
  display: flex;
  align-items: flex-end !important;
}

.cancel-btn-text {
  color: var(--text-sub-600, #525866) !important;
  text-transform: capitalize !important;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--radius-10, 10px) !important;
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
  background: var(--bg-white-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.save-btn-text {
  border-radius: var(--radius-10, 10px) !important;
  background: #512dd6 !important;
  margin-left: 15px !important;
  text-transform: capitalize !important;
}
.table-header-cell {
  background-color: #512dd6 !important;
  border-radius: 8px !important;
}
.table-styled-cell {
  background-color: transparent !important;
  color: black !important;
  overflow-y: hidden !important;
}

.reference-link {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  text-decoration: none;
  font-weight: bold;
  color: rgb(82, 13, 151);
}
.details-text {
  color: var(--text-strong-950, #0e121b) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  padding-top: 2px;
}
.not-matched-text {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  font-family: Inter !important;
  font-size: 14px !important;
  padding: 10px 0px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.MuiDialogActions-root {
  display: none !important;
}
/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  display: none !important;
} */
/* .MuiButtonBase-root-MuiButton-root-MuiPickersToolbarButton-root {
  font-size: 20px !important;
} */

/* .MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
}
.MuiTypography-root-MuiPickersToolbarText-root-MuiTimePickerToolbar-separator {
  font-size: 30px !important;
}
.MuiTypography-root-MuiPickersToolbarText-root {
  font-size: 20px !important;
}
.MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel {
  font-size: 15px !important;
} */
/* .MuiTypography-root {
  font-size: 13px !important; 
  font-weight: bold !important;
} */
/* .MuiTypography-h3 {
  font-size: 22px !important;
} */
/* .MuiTypography-root-MuiPickersToolbarText-root {
  font-size: 20px !important;
} */
/* .MuiPickersLayout-root .MuiPickersLayout-toolbar {
  margin-top: -20px;
}
.MuiTimeClock-root {
  margin-top: -20px !important;
}
.MuiPickersLayout-contentWrapper {
  margin-bottom: -15px !important;
} */
.chip-background {
  background: #e8f7f6;
  border-radius: 6px;
}
.action-table-column {
  width: 10px !important;
  padding: 0px !important;
  margin: 0px !important;
}
.add-menu-items {
  background: plum !important;
  overflow-y: scroll !important;
}

.add-table-column {
  width: 35px !important;
  height: 45px !important;
  border-radius: 8px;
  padding: 0px !important;
  margin: 0px !important;
}

.transaction-main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

/* .transaction-date-row-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

/* .expanded-transaction-date-row-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 99%;
} */

/* .table-details {
  overflow-x: scroll !important;
  width: 99%;
} */

/* .expanded-table-details {
  display: flex;
  flex-direction: column;
  width: 99%;
} */

/* @media screen and (max-width: 1450px) {
  .expanded-table-details {
    display: flex;
    flex-wrap: wrap !important;
    overflow-x: scroll !important;
    width: 83% !important;
  }
  .expanded-transaction-date-row-container {
    display: flex;
    flex-wrap: wrap !important;
    width: 83% !important;
    overflow-x: scroll !important;
  }
  .table-details {
    width: 96%;
  }
  .transaction-date-row-container {
    width: 96%;
  }
}

@media screen and (max-width: 1150px) {
  .expanded-table-details {
    display: flex;
    flex-wrap: wrap !important;
    overflow-x: scroll !important;
    width: 59% !important;
  }
  .expanded-transaction-date-row-container {
    display: flex;
    flex-wrap: wrap !important;
    width: 59% !important;
    overflow-x: scroll !important;
  }
}

@media screen and (max-width: 1350px) {
  .expanded-table-details {
    display: flex;
    flex-wrap: wrap !important;
    overflow-x: scroll !important;
    width: 80% !important;
  }
  .expanded-transaction-date-row-container {
    display: flex;
    flex-wrap: wrap !important;
    width: 80% !important;
    overflow-x: scroll !important;
  }
} */

/* @media screen and (max-width: 1070px) {
  .expanded-table-details {
    display: flex;
    flex-wrap: wrap !important;
    overflow-x: scroll !important;
    width: 85% !important;
  }
  .expanded-transaction-date-row-container {
    display: flex;
    flex-wrap: wrap !important;
    width: 85% !important;
    overflow-x: scroll !important;
  }
} */

/* @media screen and (max-width: 880px) {
  .transaction-text {
    font-size: 15px !important;
  }
  .description-text {
    font-size: 12px !important;
  }
  .pop-up-alignment {
    margin-left: 5px !important;
  }
  .date-field {
    width: 95%;
  }
  .MuiTypography-h3 {
    font-size: 15px !important;
  }
  .MuiTypography-subtitle2 {
    font-size: 10px !important;
  }
  .each-filter-frame {
    width: 200px !important;
  }

  .reload-button {
    font-size: 10px !important;
  }
  .pagination-buttons .MuiPaginationItem-root {
    font-size: 10px !important;
    min-width: 25px !important;
    height: 25px !important;
  }
  .search-input-field .MuiOutlinedInput-input {
    height: 28px !important;
    font-size: 12px !important;
    padding: 2px;
  }
  .search-input-field .MuiOutlinedInput-input::placeholder {
    font-size: 12px !important;
  }
  .calendar-container .MuiBox-root {
    height: 24px !important;
    padding-left: 10px !important;
  }
  .filter-button {
    padding: 5px !important;
  }
  .reload-button {
    padding: 5px !important;
  }
  .drop-down-container {
    padding: 5px !important;
  }
  .filter-label-text {
    font-size: 12px !important;
  }
  .filter-label-text-bold {
    font-size: 12px !important;
  }
  .search-input-field .MuiOutlinedInput-root {
    height: 30px !important;
    font-size: 10px !important;
  }
  .MuiInputLabel-root {
    font-size: 10px !important;
  }
  .filters-text {
    font-size: 15px !important;
  }
  .buttons-row-container {
    width: 100% !important;
    gap: 7px !important;
    flex-wrap: wrap;
    overflow-x: scroll !important;
  }
  .input-search-field .MuiOutlinedInput-root {
    height: 30px !important;
    font-size: 10px !important;
  }
  .dropdown-search-field {
    width: 60% !important;
  }
  .dropdown-search-field .MuiOutlinedInput-input {
    padding: 0px 0px 10px 0px !important;
  }
  .transaction-main-container {
    gap: 15px !important;
  }
  .transaction-header {
    gap: 0px !important;
    padding-right: 10px !important;
  }
  .description-text .MuiTypography-body1 {
    font-size: 12px !important;
    text-wrap: wrap;
    letter-spacing: 0px !important;
  }
  .payment-transaction-container {
    padding-left: 10px !important;
    padding-right: 30px !important;
  }
} */
/* @media screen and (max-width: 365px) {
  .filter-container-details {
    width: 220px !important;
  }
  .cancel-btn-text {
    font-size: 12px !important;
  }
  .save-btn-text {
    font-size: 12px !important;
  }
  .payment-transaction-container {
    padding: 10px 40px 5px 10px !important;
  }
  .pagination-buttons {
    font-size: 5px !important;
  }
} */
.filter-reload-container {
  display: flex;
}

/* @media screen and (max-width: 576px) {
  .filter-reload-container {
    margin-top: 10px;
  }
} */

@media screen and (max-width: 550px) {
  .MuiPaginationItem-root {
    margin: 5px !important;
  }
}
.dropdown-search-field {
  height: 40px !important;
}
