.merchant-name-row-container {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  margin: 20px 0px;
}
.merchant-name-container {
  display: flex;
  width: 500px;
  align-items: center;
  gap: 14px;
}
.merchant-name {
  /* color: var(--text-strong-950, #0e121b) !important;
    font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.084px; */
  color: var(--text-strong-950, #0e121b) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.merchant-name-input-field-container {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8);
  background: var(--bg-white-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  width: 100%;
}
.merchant-name-input-field {
  color: var(--text-soft-400, #717784);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  flex: 1 0 0;
}
.google-pay-settings-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.27px;
  display: flex;
  align-self: stretch;
}

.error-message {
  margin-bottom: -20px;
  padding-top: 3px;
  text-align: start;
  padding-left: 10px;
  padding-bottom: 5px;
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.hide-text {
  display: none;
}

@media screen and (max-width: 576px) {
  .merchant-name-row-container {
    display: flex;
    flex-direction: column !important;
    gap: 10px;
  }
  .merchant-name-container {
    display: flex;
    width: 100%;
    align-items: start !important;
  }
  .google-pay-settings-text {
    font-size: 15px !important;
  }
}
