.table-row-header {
  display: flex !important;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px !important;
  background: var(--bg-weak-50, #f5f7fa);
}
.name-label-container {
  /* background: var(--bg-weak-50, plum); */
  display: flex !important;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
.label-container {
  display: flex !important;
  align-items: center !important;
}

.name-text {
  color: var(--text-sub-600, #525866);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.up-down-label-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.token-label-container {
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}
.token-text {
  color: var(--text-sub-600, #525866);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.084px;
}

/*---------Public-Table-Data------------*/
.public-label-container {
  display: flex;
  width: 100%;
  padding: 12px 20px 12px 12px;
  align-items: center;
  gap: 12px;
  background: var(--bg-white-0, #fff);
}
.public-text {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.password-label-container {
  display: flex;
  width: 100%;
  padding: 12px 20px 12px 12px;
  align-items: center;
  gap: 12px;
  background: var(--bg-white-0, #fff);
}
.password-text {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.regenerate-label-container {
  display: flex;
  width: 100%;
  padding: 12px 20px 12px 12px;
  align-items: center;
  gap: 12px;
  background: var(--bg-white-0, #fff);
}
.refresh-button {
  color: var(--text-strong-950, #0e121b) !important;
}
.generate-text {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  text-transform: capitalize;
}
/*----------Private- Table-Data------------------*/
.private-label-container {
  display: flex;
  width: 100%;
  padding: 12px 20px 12px 12px;
  align-items: center;
  gap: 12px;
  background: var(--bg-white-0, #fff);
  /* background: #7abe76; */
}
.private-text {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.not-generate-label-container {
  display: flex;
  width: 100%;
  padding: 12px 20px 12px 12px;
  align-items: center;
  gap: 12px;
  background: var(--bg-white-0, #fff);
  /* background: #8459b9; */
}
.not-generate-text {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  text-transform: capitalize;
}
.generative-refresh-text {
  overflow: hidden;
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  text-transform: capitalize;
}
.regenerate-label-containers {
  display: flex;
  width: 100%;
  padding: 12px 20px 12px 12px;
  align-items: center;
  gap: 12px;
  background: var(--bg-white-0, #fff);
  /* background: #a0c15e; */
}

/* @media screen and (max-width:880px){
  .updown-fill{
    font-size: 10px !important;
  }
} */
