.icon-size {
  height: 20px;
  width: 20px;
}

.caret-left-icon {
  height: 32px;
  width: 32px;
}

/* @media screen and (max-width: 880px) {
  .icon-size {
    height: 15px !important;
    width: 15px !important;
  }
  .caret-left-icon {
    height: 15px !important;
    width: 15px !important;
  }
} */
