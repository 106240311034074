.webhook-and-search-url-container {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.content-label {
  display: flex;
  width: 500px;
  align-items: center;
  gap: 14px;
}
.webhooks-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.27px;
  display: flex;
  align-self: stretch;
}
.webhook-url-text {
  color: var(--text-strong-950, #0e121b) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: -0.084px;
}
.search-layout-cont {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8);
  background: var(--bg-white-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
  width: 100%;
}
.search-bar {
  color: var(--text-soft-400, #717784);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  flex: 1 0 0;
}
.hr-line-label-container {
  padding: 1.5px 0px;
  gap: 8px;
  margin: 30px 0px;
}
.hr-line {
  background: var(--stroke-soft-200, #cacfd8);
  height: 1px;
  flex: 1 0 0;
}
/*-----------Payment Types-----------------*/

.payment-status-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: -0.176px;
  display: flex;
  align-self: stretch;
  padding: 10px !important;
}
.payments-border-container {
  border: 1px solid var(--stroke-soft-200, #cacfd8);
  border-radius: 8px;
  margin: 20px 0px;
  min-width: 235px !important;
  padding: 20px;
}
.payment-status-row-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* padding: 10px; */
}
.each-checkbox-container {
  display: flex;
  width: 350px;
  align-items: flex-start;
  gap: 8px;
  margin: 10px;
}

@media screen and (max-width: 991px) {
  .each-checkbox-container {
    width: 280px !important;
  }
}
.checkbox-text {
  color: var(--text-strong-950, #0e121b);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.error-message {
  margin-bottom: -20px;
  padding-top: 3px;
  text-align: start;
  padding-left: 10px;
  padding-bottom: 5px;
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.hide-text {
  display: none;
}
.webhook-save-button {
  border-radius: var(--radius-10, 10px) !important;
  background: #512dd6 !important;
  color: "white" !important;
  margin-left: 15px !important;
  text-transform: capitalize !important;
}

label.MuiFormControlLabel-root .MuiCheckbox-root.Mui-checked {
  color: #1b84ff !important;
}

label.MuiFormControlLabel-root .MuiTypography-root {
  font-family: Inter !important;
  font-size: 13px !important;
  color: #252f4a;
}

@media screen and (max-width: 576px) {
  .webhook-and-search-url-container {
    display: flex;
    flex-direction: column !important;
    gap: 15px !important;
  }
  .content-label {
    display: flex;
    align-items: start !important;
    width: 100%;
  }
}
