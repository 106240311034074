.button-background-color {
  background: #512dd6 !important;
  color: white !important;
  text-transform: capitalize !important;
  border-radius: var(--radius-10, 10px) !important;
  font-size: 14px !important;
}

.normal-button {
  text-transform: capitalize !important;
  border-radius: var(--radius-10, 10px);
  color: var(--text-sub-600, #525866) !important;
  font-size: 14px !important;
  border-radius: var(--radius-10, 10px) !important;
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
}

@media screen and (max-width: 880px) {
  .button-background-color {
    font-size: 13px !important;
  }
  .normal-button {
    font-size: 11px !important;
    width: 100px !important;
  }
  .button-background-color {
    font-size: 11px !important;
    width: 100px !important;
  }
}
